// Pack for app/views/layouts/application.html.haml only

import { csrfToken } from "src/helpers/ajax"

$("body").on("touchmove", (e) => {
  e.stopPropagation()
})

$.ajaxSetup({
  beforeSend: (xhr) => {
    xhr.setRequestHeader("X-CSRF-Token", csrfToken())
  }
})
